<template>
    <div class="hc21Box">
        <div
            class="hbBox1"
            v-if="getAdvData(19).children"
            :style="`background: url(${getAdvData(19).children[0].image}) no-repeat center;`"
        >
            <a :href="getAdvData(19).children[0].url" target="_blank" rel="noopener noreferrer"></a>
        </div>
        <div class="hbBox2">
            <div class="hb2Top">
                威有科技将企业信息、服务、活动等内容通过微信网页的形式进行表现，仅需简单设置，就能生成微信3G网站。
                <br />
                并借助微信交际功能，形成集展示、推广、服务、营销为一体的综合性宣传平台
            </div>
            <div class="hb2List">
                <ul>
                    <li class="hb2Li" v-for="item in 4" :key="item">
                        <div class="hl1"></div>
                        <p class="hl2">微商城、微官网、企业号、服务号定制开发</p>
                    </li>
                </ul>
            </div>
        </div>
        <div class="hbBox3">
            <div class="hb3Top">微信开发功能</div>
            <div class="hb3Box">
                <div class="h3Left">
                    <ul>
                        <li
                            class="h3lLi"
                            :class="{ showH1: activeId === item.id }"
                            v-for="item in list.slice(0, 4)"
                            :key="item.id"
                            @mousemove="activeId = item.id"
                        >
                            <div class="h3lBox">
                                <div class="hb1">
                                    <img src="http://iv.vu818.com/img/icon_75.png" alt="" class="h1Img1" />
                                    <img src="https://iv.vu818.com/img/icon_100.png" alt="" class="h1Img2" />
                                </div>
                                <p class="hb2">{{ item.title }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="h3Right">
                    <p class="h3r1" v-if="list.find(e => e.id == activeId)">{{ list.find(e => e.id == activeId).title }}</p>
                    <p class="h3r2" v-for="el in textList" :key="el">
                        {{ el }}
                    </p>
                </div>
                <div class="h3Img">
                    <!-- <img src="http://iv.vu818.com/img/hc2331.png" alt="" /> -->
                    <vy-phone v-for="el in list.slice(0, 4)" :key="el.id" :element="el" v-show="el.id == activeId" />
                </div>
            </div>
            <img src="http://iv.vu818.com/img/hc2332.png" alt="" class="hb3Img1" />
            <img src="http://iv.vu818.com/img/hc2333.png" alt="" class="hb3Img2" />
            <!-- <div class="h3B"></div> -->
        </div>
        <div class="hbBox4">
            <div class="hb4Top">
                <p class="h4t1">解决方案</p>
                <p class="h4t2">威有科技以专业的服务态度，将市面上现有APP归纳为类别为企业客户提供具针对性的开发解决方案</p>
            </div>
            <div class="hb4List">
                <ul>
                    <li class="hb4Li" @mousemove="showMove(1)" @mouseleave="showLea(1)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon1 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img" />
                                <img v-show="showIcon1 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img12" />
                            </div>
                            <p class="hb2">家政</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(2)" @mouseleave="showLea(2)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon2 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img2" />
                                <img v-show="showIcon2 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img22" />
                            </div>
                            <p class="hb2">服饰</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(3)" @mouseleave="showLea(3)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon3 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img3" />
                                <img v-show="showIcon3 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img32" />
                            </div>
                            <p class="hb2">美容</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(4)" @mouseleave="showLea(4)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon4 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img4" />
                                <img v-show="showIcon4 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img42" />
                            </div>
                            <p class="hb2">外卖</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(5)" @mouseleave="showLea(5)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon5 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img5" />
                                <img v-show="showIcon5 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img52" />
                            </div>
                            <p class="hb2">餐饮</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(6)" @mouseleave="showLea(6)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon6 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img6" />
                                <img v-show="showIcon6 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img62" />
                            </div>
                            <p class="hb2">直播</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(7)" @mouseleave="showLea(7)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon7 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img7" />
                                <img v-show="showIcon7 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img72" />
                            </div>
                            <p class="hb2">快递</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(8)" @mouseleave="showLea(8)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon8 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img8" />
                                <img v-show="showIcon8 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img82" />
                            </div>
                            <p class="hb2">医疗</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(9)" @mouseleave="showLea(9)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon9 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img9" />
                                <img v-show="showIcon9 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img92" />
                            </div>
                            <p class="hb2">理财</p>
                        </div>
                    </li>
                    <li class="hb4Li" @mousemove="showMove(10)" @mouseleave="showLea(10)">
                        <div class="h4lBox">
                            <div class="hb1">
                                <img v-show="showIcon10 === 1" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img10" />
                                <img v-show="showIcon10 === 2" src="http://iv.vu818.com/img/vu818.png" alt="" class="h1Img102" />
                            </div>
                            <p class="hb2">旅游</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="hbBox5">
            <div class="hb5Top">客户案例</div>
            <div class="hb5List">
                <ul>
                    <li v-for="item in list.slice(4, 10)" :key="item.id">
                        <vy-phone :element="item" />
                    </li>
                </ul>
            </div>
        </div>
        <div class="hbBox6">
            <img src="http://iv.vu818.com/img/icon_39.png" alt="" class="h6Top wow animate__animated animate__slideInDown" />
            <div class="hb6Top">开发优势</div>
            <div class="hb6Box">
                <ul>
                    <li class="hb6Li" v-for="item in 4" :key="item">
                        <p class="hl1">技术实力</p>
                        <div class="hl2">
                            <div class="h2Img"></div>
                        </div>
                        <div class="hb6T">
                            <p class="htp">
                                15年互联网服务经验，20000+企业客户共同选择。 以不断追求精进的技术理念，
                                创造出成熟的标准化开发流程。确保客户在这里能 够享受到更专业，更优质的服务。
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
            <img src="http://iv.vu818.com/img/hc2333.png" alt="" class="hb6Sun" />
            <img src="http://iv.vu818.com/img/hc1764.png" alt="" class="hb6Yun" />
            <img src="http://iv.vu818.com/img/icon_35.png" alt="" class="h6l1" />
            <img src="http://iv.vu818.com/img/icon_34.png" alt="" class="h6l2" />
            <img src="http://iv.vu818.com/img/icon_34.png" alt="" class="h6r1" />
            <div class="hb6B"></div>
        </div>
        <div class="hbBox7">
            <div class="hb7Top">开发流程</div>
            <img src="http://iv.vu818.com/img/icon_52.png" alt="" class="hb7Bg wow animate__animated animate__slideInUp" />
            <div class="hb7Box">
                <ul class="toRight">
                    <li class="hb7Li" v-for="item in 8" :key="item" @mouseenter="stop(item)" @mouseleave="start">
                        <div class="hl1" v-if="item % 2" :style="{ opacity: hoverItem == item ? 0 : 1 }">
                            <i class="el-icon-edit"></i>
                        </div>
                        <p class="hl2" :style="{ color: item % 2 ? 'white' : 'black' }">评估需求</p>
                        <div class="hl1" v-if="!(item % 2)" :style="{ opacity: hoverItem == item ? 0 : 1 }">
                            <i class="el-icon-edit"></i>
                        </div>
                        <div class="hlT" :style="{ opacity: hoverItem == item ? 1 : 0 }" :class="{ xia: !(item % 2) }">
                            <div class="ht1"><i class="el-icon-edit"></i></div>
                            <div class="ht2">需求收集、定位分析、可行性分析</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import VyPhone from "@/util/VyPhone.vue";
export default {
    components: { VyPhone },
    name: "HsContent21",
    inject: ["getWebData", "getAdvData"],
    data() {
        return {
            activeId: null,
            showIcon1: 1,
            showIcon2: 1,
            showIcon3: 1,
            showIcon4: 1,
            showIcon5: 1,
            showIcon6: 1,
            showIcon7: 1,
            showIcon8: 1,
            showIcon9: 1,
            showIcon10: 1,
            hoverItem: 1,
            timer: null,
            list: [],
            textList: [
                "a微网站【多种模板自由选择，一键建站轻松管理】",
                "b微网站【多种模板自由选择，一键建站轻松管理】",
                "c微网站【多种模板自由选择，一键建站轻松管理】",
                "d微网站【多种模板自由选择，一键建站轻松管理】",
                "e微网站【多种模板自由选择，一键建站轻松管理】",
            ],
        };
    },
    async mounted() {
        await this.getData();
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
        function show() {
            let dom = document.getElementsByClassName("hb7Box");
            if (dom[0].getBoundingClientRect().top < 500) {
                let toRight = document.getElementsByClassName("toRight");
                toRight[0].style.width = "100%";
                window.removeEventListener("scroll", show);
            }
        }
        window.addEventListener("scroll", show);
        this.start();
    },
    methods: {
        showMove(i) {
            switch (i) {
                case 1:
                    this.showIcon1 = 2;
                    break;
                case 2:
                    this.showIcon2 = 2;
                    break;
                case 3:
                    this.showIcon3 = 2;
                    break;
                case 4:
                    this.showIcon4 = 2;
                    break;
                case 5:
                    this.showIcon5 = 2;
                    break;
                case 6:
                    this.showIcon6 = 2;
                    break;
                case 7:
                    this.showIcon7 = 2;
                    break;
                case 8:
                    this.showIcon8 = 2;
                    break;
                case 9:
                    this.showIcon9 = 2;
                    break;
                case 10:
                    this.showIcon10 = 2;
                    break;
            }
        },
        showLea(i) {
            switch (i) {
                case 1:
                    this.showIcon1 = 1;
                    break;
                case 2:
                    this.showIcon2 = 1;
                    break;
                case 3:
                    this.showIcon3 = 1;
                    break;
                case 4:
                    this.showIcon4 = 1;
                    break;
                case 5:
                    this.showIcon5 = 1;
                    break;
                case 6:
                    this.showIcon6 = 1;
                    break;
                case 7:
                    this.showIcon7 = 1;
                    break;
                case 8:
                    this.showIcon8 = 1;
                    break;
                case 9:
                    this.showIcon9 = 1;
                    break;
                case 10:
                    this.showIcon10 = 1;
                    break;
            }
        },
        // 停止随机显示
        stop(item) {
            clearInterval(this.timer);
            this.hoverItem = item;
        },
        // 开启随机显示
        start() {
            // 随机显示一项
            this.timer = setInterval(() => {
                function sjs(n) {
                    let num = Math.floor(Math.random() * 8) + 1;
                    if (num === n) return sjs(n);
                    return num;
                }
                this.hoverItem = sjs(this.hoverItem);
            }, 4000);
        },
        async getData() {
            const { data } = await axios.get("api/project/other");
            this.list = data;
            this.activeId = data[0] ? data[0].id : "";
        },
    },
};
</script>

<style lang="less" scoped>
.hc21Box {
    width: 100%;
    margin-top: 78px;
    .hbBox1 {
        width: 100%;
        height: 588px;
        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .hbBox2 {
        width: 100%;
        height: 480px;
        background: url("http://iv.vu818.com/img/icon_74.png") no-repeat top center;
        overflow: hidden;
        .hb2Top {
            width: 100%;
            height: 45px;
            text-align: center;
            margin-top: 50px;
        }
        .hb2List {
            max-width: 1280px;
            height: 254px;
            margin: 0 auto;
            margin-top: 50px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .hb2Li {
                    width: 300px;
                    height: 254px;
                    cursor: pointer;
                    .hl1 {
                        width: 100%;
                        height: 196px;
                        background: url("http://iv.vu818.com/img/icon_70.png") no-repeat center;
                        transition: 0.5s;
                    }
                    .hl2 {
                        width: 100%;
                        height: 58px;
                        line-height: 58px;
                        text-align: center;
                        font-size: 14px;
                    }
                }
                .hb2Li:hover {
                    .hl1 {
                        transform: translateY(-10px);
                    }
                }
            }
        }
    }
    .hbBox3 {
        width: 100%;
        // height: 625px;
        background: #f1faff;
        overflow: hidden;
        position: relative;
        padding: 50px 0;
        .hb3Top {
            width: 100%;
            height: 76px;
            line-height: 76px;
            text-align: center;
            font-size: 36px;
            font-weight: bold;
            color: #60646a;
        }
        .hb3Box {
            max-width: 1280px;
            // height: 472px;
            margin: 0 auto;
            margin-top: 50px;
            .h3Left {
                width: 400px;
                height: 392px;
                float: left;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    .h3lLi {
                        width: 145px;
                        height: 176px;
                        border: 3px solid #bcdcee;
                        border-radius: 25px;
                        background: #fff;
                        box-sizing: border-box;
                        transition: 0.5s;
                        .h3lBox {
                            width: 90%;
                            height: 90%;
                            margin: 0 auto;
                            margin-top: 10px;
                            overflow: hidden;
                            .hb1 {
                                width: 100%;
                                height: 90px;
                                // background: url("vu818.com/vu818com/img/mobile/images/icon_75.png")
                                //   no-repeat center;
                                margin-top: 10px;
                                position: relative;
                                .h1Img1 {
                                    position: absolute;
                                    top: 0px;
                                    left: 40px;
                                    transition: 0.5s;
                                }
                                .h1Img2 {
                                    position: absolute;
                                    top: 0px;
                                    left: 40px;
                                    transition: 0.5s;
                                    opacity: 0;
                                }
                            }
                            .hb2 {
                                width: 100%;
                                height: 19px;
                                line-height: 19px;
                                font-size: 14px;
                                margin-top: 20px;
                                padding-left: 10px;
                            }
                        }
                    }
                    .showH1 {
                        border: 3px solid #3bb6eb;
                        background: #3bb6eb;
                        box-shadow: 0px 10px 40px 0px #93d7f4;
                        transform: translateY(-4px);
                        color: white;
                        .h3lBox .hb1 {
                            .h1Img1 {
                                opacity: 0;
                            }
                            .h1Img2 {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            .h3Right {
                width: 450px;
                height: 388px;
                float: left;
                margin-left: 30px;
                .h3r1 {
                    width: 100%;
                    height: 58px;
                    line-height: 58px;
                    font-size: 24px;
                }
                .h3r2 {
                    width: 100%;
                    height: 60px;
                    line-height: 60px;
                    font-size: 14px;
                    color: gray;
                }
                .h3r2:nth-child(1) {
                    margin-top: 20px;
                }
            }
            .h3Img {
                // height: 418px;
                float: left;
            }
        }
        .h3B {
            position: absolute;
            bottom: 0;
            width: 1920px;
            height: 95px;
            border-top: 2px solid #b3d7ec;
            // border-bottom: 2px solid #b3d7ec;
            background: #fff;
            margin-left: -960px px;
        }
        .hb3Img1 {
            position: absolute;
            left: 10px;
            bottom: 0px;
        }
        .hb3Img2 {
            position: absolute;
            right: 10px;
            top: 150px;
        }
    }
    .hbBox4 {
        width: 100%;
        height: 650px;
        overflow: hidden;
        .hb4Top {
            width: 100%;
            height: 149px;
            text-align: center;
            margin-top: 30px;
            .h4t1 {
                width: 100%;
                height: 84px;
                line-height: 84px;
                font-size: 36px;
                font-weight: bold;
                color: #60646a;
            }
            .h4t2 {
                width: 100%;
                height: 65px;
                line-height: 65px;
                font-size: 14px;
                color: gray;
            }
        }
        .hb4List {
            max-width: 915px;
            height: 416px;
            margin: 0 auto;
            margin-top: 50px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                .hb4Li {
                    width: 143px;
                    height: 173px;
                    border-radius: 20px;
                    background-color: #eee;
                    margin-left: 10px;
                    cursor: pointer;
                    transition: 0.5s;
                    .h4lBox {
                        width: 90%;
                        height: 80%;
                        margin: 0 auto;
                        margin-top: 10%;
                        .hb1 {
                            width: 55%;
                            height: 64px;
                            margin: 0 auto;
                            margin-top: 30px;
                            position: relative;
                            overflow: hidden;
                            img {
                                display: block;
                                margin-left: 20px;
                            }
                            .h1Img {
                                position: absolute;
                                top: -532px;
                                left: -756px;
                            }
                            .h1Img12 {
                                width: 1700px;
                                height: 2600px;
                                position: absolute;
                                top: -621px;
                                left: -17px;
                            }
                            .h1Img2 {
                                position: absolute;
                                top: -1050px;
                                right: -328px;
                            }
                            .h1Img22 {
                                position: absolute;
                                top: -1116px;
                                right: -328px;
                            }
                            .h1Img3 {
                                position: absolute;
                                top: -531px;
                                left: -590px;
                            }
                            .h1Img32 {
                                width: 1700px;
                                height: 2680px;
                                position: absolute;
                                top: -638px;
                                left: -85px;
                            }
                            .h1Img4 {
                                position: absolute;
                                top: -532px;
                                right: -459px;
                            }
                            .h1Img42 {
                                position: absolute;
                                top: -609px;
                                left: -145px;
                            }
                            .h1Img5 {
                                position: absolute;
                                top: -680px;
                                left: -10px;
                            }
                            .h1Img52 {
                                width: 1700px;
                                height: 2600px;
                                position: absolute;
                                top: -617px;
                                left: -229px;
                            }
                            .h1Img6 {
                                position: absolute;
                                top: -531px;
                                left: -672px;
                            }
                            .h1Img62 {
                                width: 2000px;
                                height: 3000px;
                                position: absolute;
                                top: -720px;
                                left: -343px;
                            }
                            .h1Img7 {
                                position: absolute;
                                top: -525px;
                                right: 1px;
                            }
                            .h1Img72 {
                                width: 1700px;
                                height: 2600px;
                                position: absolute;
                                top: -618px;
                                left: -356px;
                            }
                            .h1Img8 {
                                position: absolute;
                                top: -680px;
                                left: -90px;
                            }
                            .h1Img82 {
                                width: 1613px;
                                height: 2600px;
                                position: absolute;
                                top: -617px;
                                left: -402px;
                            }
                            .h1Img9 {
                                position: absolute;
                                top: -532px;
                                left: -968px;
                            }
                            .h1Img92 {
                                width: 1750px;
                                height: 2700px;
                                position: absolute;
                                top: -645px;
                                left: -511px;
                            }
                            .h1Img10 {
                                position: absolute;
                                top: -536px;
                                left: -896px;
                            }
                            .h1Img102 {
                                width: 1600px;
                                height: 2600px;
                                position: absolute;
                                top: -619px;
                                left: -538px;
                            }
                        }
                        .hb2 {
                            width: 100%;
                            height: 14px;
                            font-size: 14px;
                            margin-top: 20px;
                            text-align: center;
                        }
                    }
                }
                .hb4Li:hover {
                    background-color: skyblue;
                    transform: translateY(-10px);
                }
            }
        }
    }
    .hbBox5 {
        width: 100%;
        // height: 700px;
        overflow: hidden;
        .hb5Top {
            width: 100%;
            height: 76px;
            text-align: center;
            line-height: 76px;
            // margin-top: 100px;hb6B
            font-size: 36px;
            font-weight: bold;
            color: #60646a;
        }
        .hb5List {
            max-width: 1280px;
            // height: 596px;
            margin: 0 auto;
            margin-top: 50px;
            & > ul {
                display: flex;
                flex-wrap: wrap;
                // justify-content: space-around;
                & > li {
                    width: 25%;
                    // margin: 0 3% 10px;
                    margin-bottom: 20px;
                    & > div {
                        margin: 0 auto;
                    }
                }
            }
        }
    }
    .hbBox6 {
        width: 100%;
        height: 824px;
        overflow: hidden;
        background: #f1faff;
        position: relative;
        .hb6Sun {
            position: absolute;
            transition: margin 0.5s;
            top: 215px;
            left: 100px;
            animation: zy-dx2 15s infinite linear;
        }
        @keyframes zy-dx2 {
            0% {
                transform: translate(0, 0);
            }
            25% {
                transform: translate(25px, -20px);
            }
            50% {
                transform: translate(50px, 0px);
            }
            75% {
                transform: translate(25px, -20px);
            }
            100% {
                transform: translate(0, 0);
            }
        }
        .hb6Yun {
            position: absolute;
            transition: margin 0.5s;
            top: 215px;
            right: 100px;
            animation: zy-dx1 20s infinite linear;
        }
        @keyframes zy-dx1 {
            0% {
                transform: translateX(0);
            }
            25% {
                transform: translateX(50px);
            }
            50% {
                transform: translateX(0px);
            }
            75% {
                transform: translateX(50px);
            }
            100% {
                transform: translateX(0);
            }
        }
        .h6Top {
            position: absolute;
            left: 0;
        }
        .hb6Top {
            width: 100%;
            height: 76px;
            line-height: 76px;
            text-align: center;
            font-size: 36px;
            font-weight: bold;
            color: #60646a;
            margin-top: 100px;
            position: relative;
        }
        .hb6Box {
            max-width: 1207px;
            height: 391px;
            margin: 0 auto;
            margin-top: 157px;
            background: url("http://iv.vu818.com/img/icon_31.png") no-repeat bottom center;
            position: relative;
            ul {
                width: 100%;
                height: 100%;
                .hb6Li {
                    width: 60px;
                    height: 86px;
                    cursor: pointer;
                    .hl1 {
                        width: 100%;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        font-size: 15px;
                    }
                    .hl2 {
                        width: 46px;
                        height: 46px;
                        border-radius: 50%;
                        background: url("http://iv.vu818.com/img/icon_42.png");
                        .h2Img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            background: url("http://iv.vu818.com/img/icon_96.png") no-repeat center;
                        }
                    }
                    .hb6T {
                        opacity: 0;
                        transition: 0.5s;
                        position: absolute;
                        top: -88px;
                        right: 50%;
                        z-index: 99;
                        width: 386px;
                        margin-right: -193px;
                        padding: 10px 30px;
                        border: 2px solid #b6d8ec;
                        border-radius: 50px;
                        background: #fff;
                        box-sizing: border-box;
                        box-shadow: -8px 8px 0px #fff;
                        pointer-events: none;
                    }
                }
                .hb6Li:hover {
                    .hb6T {
                        opacity: 1;
                    }
                }
                .hb6Li:nth-child(1) {
                    position: absolute;
                    top: 15px;
                    left: 265px;
                }
                .hb6Li:nth-child(2) {
                    position: absolute;
                    top: -55px;
                    left: 460px;
                }
                .hb6Li:nth-child(3) {
                    position: absolute;
                    top: -25px;
                    right: 420px;
                }
                .hb6Li:nth-child(4) {
                    position: absolute;
                    top: 20px;
                    right: 210px;
                }
            }
        }
        .h6l1 {
            position: absolute;
            left: 80px;
            bottom: 100px;
        }
        .h6l2 {
            position: absolute;
            left: -60px;
            bottom: -50px;
        }
        .h6r1 {
            position: absolute;
            right: -20px;
            bottom: 0px;
            z-index: 5;
        }
        .hb6B {
            position: absolute;
            left: 50%;
            bottom: 0;
            width: 1920px;
            height: 95px;
            border-top: 2px solid #b3d7ec;
            border-bottom: 2px solid #b3d7ec;
            background: #fff;
            margin-left: -960px;
        }
    }
    .hbBox7 {
        width: 100%;
        height: 540px;
        overflow: hidden;
        background: url("https://iv.vu818.com/img/icon_51.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        margin-top: -100px;
        z-index: 10;
        .hb7Top {
            width: 100%;
            height: 76px;
            line-height: 76px;
            text-align: center;
            font-size: 36px;
            font-weight: bold;
            margin-top: 20px;
            color: white;
        }
        .hb7Bg {
            width: 135%;
            left: -19%;
            // height: 100%;
            height: 600px;
            z-index: 5;
            position: absolute;
        }
        .hb7Box {
            // max-width: 1280px;
            width: 89%;
            height: 100%;
            margin: 0 auto;
            margin-top: 150px;
            position: relative;
            z-index: 6;
            ul {
                transition: width 2s;
                width: 0%;
                height: 84px;
                background: url("http://iv.vu818.com/img/icon_53.png") no-repeat center;
                background-size: 100% 100%;
                .hb7Li {
                    width: 60px;
                    height: 75px;
                    cursor: pointer;
                    i {
                        font-size: 40px;
                        line-height: 62px;
                        color: #3bb6eb;
                    }
                    .hl1 {
                        width: 100%;
                        height: 50px;
                        transition: 0.5s;
                    }
                    .hl2 {
                        width: 100%;
                        height: 25px;
                        line-height: 25px;
                        font-size: 15px;
                        color: #60646a;
                        font-weight: bold;
                    }
                    .hlT {
                        width: 268px;
                        height: 68px;
                        border-radius: 30px;
                        background-color: #fff;
                        box-shadow: 1px 1px 10px 1px gainsboro;
                        position: absolute;
                        top: -35px;
                        left: -105px;
                        opacity: 0;
                        transition: 0.5s;

                        .ht1 {
                            width: 63px;
                            height: 63px;
                            border-radius: 50%;
                            float: left;
                            margin-top: 2px;
                            margin-left: 2px;
                            background-color: #eee;
                        }
                        .ht2 {
                            width: 180px;
                            height: 51px;
                            float: left;
                            font-size: 14px;
                            margin-left: 10px;
                            margin-top: 10px;
                            text-align: left;
                        }
                    }
                    .hlT::after {
                        position: absolute;
                        top: 100%;
                        left: 50%;
                        content: " ";
                        width: 17px;
                        height: 5px;
                        margin-left: -3px;
                        background: url("http://iv.vu818.com/img/icon_54.png") no-repeat center;
                    }
                    .xia {
                        top: 40px;
                        &::after {
                            top: -5px;
                            transform: rotate(180deg);
                        }
                    }
                }
                .hb7Li:hover {
                    .hlT {
                        // opacity: 1;
                    }
                }
                .hb7Li::before {
                    position: absolute;
                    bottom: -26px;
                    left: 50%;
                    content: " ";
                    width: 12px;
                    height: 12px;
                    margin-left: -6px;
                    border: 4px solid #fff;
                    border-radius: 50%;
                    box-sizing: border-box;
                }
                .hb7Li:nth-child(1) {
                    position: absolute;
                    top: -16%;
                    left: 8%;
                }
                .hb7Li:nth-child(2) {
                    position: absolute;
                    top: 12%;
                    left: 21%;
                }
                .hb7Li:nth-child(3) {
                    position: absolute;
                    top: -16%;
                    left: 33%;
                }
                .hb7Li:nth-child(4) {
                    color: black;
                    position: absolute;
                    top: 12%;
                    left: 43%;
                }
                .hb7Li:nth-child(5) {
                    position: absolute;
                    top: -16%;
                    left: 54%;
                }
                .hb7Li:nth-child(6) {
                    color: black;
                    position: absolute;
                    top: 11%;
                    left: 65%;
                }
                .hb7Li:nth-child(7) {
                    position: absolute;
                    top: -17%;
                    right: 19%;
                }
                .hb7Li:nth-child(8) {
                    color: black;
                    position: absolute;
                    top: 11%;
                    right: 5%;
                }
                .hb7Li:nth-child(2)::before,
                .hb7Li:nth-child(4)::before,
                .hb7Li:nth-child(6)::before,
                .hb7Li:nth-child(8)::before {
                    position: absolute;
                    top: -26px;
                    left: 50%;
                    content: " ";
                    width: 12px;
                    height: 12px;
                    margin-left: -6px;
                    border: 4px solid #fff;
                    border-radius: 50%;
                    box-sizing: border-box;
                }
            }
        }
    }
}
</style>
